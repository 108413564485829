
import { Component, Vue } from 'vue-property-decorator';
import Grid from '@/components/grid/Grid';
import GridCell from '@/components/grid/GridCell';
import { Promised } from 'vue-promised';
import { resetData } from '@/api/om27.api';
import GDialog from '@/components/gsk-components/GskDialog.vue';
import { RawLocation } from 'vue-router';
import { RouteNames } from '@/constants';

@Component({
  components: {
    Grid,
    GridCell,
    Promised,
    GDialog,
  },
})
export default class SignOff extends Vue {
  get link(): RawLocation {
    return {
      name: RouteNames.Om27,
    };
  }
  promise: Promise<unknown> | null = null;
  created(): void {
    this.promise = resetData();
  }
}
